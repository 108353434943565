.react-phone-div {
  width: 100%;
  padding: 0px !important;
  background-color: transparent;
  border: none !important;
  .phone_prefix {
    display: flex;
    width: 100%;
    .phone-div {
      width: 100%;
      display: flex;
    }
    .phonePrefixRegion {
      position: relative;
      width: 95px;
    }
    .countryCode {
      position: absolute;
      right: 20px;
      top: 8px;
    }
    .react-tel-input {
      .form-control {
        width: 100% !important;
        background-color: var(--inputColor) !important;
        border: 1px solid var(--borderColor) !important;
      }
      .flag-dropdown {
        // width: 100%;
        width: 42px;
        background-color: var(--inputColor) !important;
        border-top: 0px !important;
        border-right: 1px solid var(--borderColor) !important;
        border-bottom: 1px solid var(--borderColor) !important;
        //border-bottom: 0px !important;
        border-left: 0px !important;
        .selected-flag {
          width: 100%;
          z-index: 1;
        }
        .arrow {
          border-top: 4px solid #fff !important;
        }
        .country-list {
          background-color: var(--fourthColor) !important;
          text-align: left;
          .dial-code {
            color: #fff !important;
          }
          .highlight {
            .country-name {
              color: var(--fourthColor) !important;
            }
            .dial-code {
              color: var(--fourthColor) !important;
            }
          }
          li:hover {
            .country-name {
              color: var(--fourthColor) !important;
            }
            .dial-code {
              color: var(--fourthColor) !important;
            }
          }
        }
      }
      .flag-dropdown:hover {
        .selected-flag {
          background-color: var(--fourthColor) !important;
        }
      }
      .flag-dropdown:focus {
        .selected-flag {
          background-color: var(--fourthColor) !important;
        }
      }
    }
  }
  .arr-phone-perfix {
    width: 100%;
    .arr-phone-div {
      display: flex;
      .people-phone {
        width: 55%;
        margin-right: 20px;
      }
    }
    .phonePrefixRegion {
      position: relative;
      width: 95px;
    }
    .countryCode {
      position: absolute;
      right: 20px;
      top: 8px;
    }
    .react-tel-input {
      .form-control {
        width: 100% !important;
        background-color: var(--inputColor) !important;
        border: 1px solid var(--borderColor) !important;
      }
      .flag-dropdown {
        width: 100%;
        background-color: var(--inputColor) !important;
        border-top: 0px !important;
        border-right: 1px solid var(--borderColor) !important;
        border-bottom: 1px solid var(--borderColor) !important;
        //border-bottom: 0px !important;
        border-left: 0px !important;
        .selected-flag {
          width: 100%;
          z-index: 1;
        }
        .arrow {
          border-top: 4px solid #fff !important;
        }
        .country-list {
          background-color: var(--fourthColor) !important;
          text-align: left;
          .dial-code {
            color: #fff !important;
          }
          .highlight {
            .country-name {
              color: var(--fourthColor) !important;
            }
            .dial-code {
              color: var(--fourthColor) !important;
            }
          }
          li:hover {
            .country-name {
              color: var(--fourthColor) !important;
            }
            .dial-code {
              color: var(--fourthColor) !important;
            }
          }
        }
      }
      .flag-dropdown:hover {
        .selected-flag {
          background-color: var(--fourthColor) !important;
        }
      }
      .flag-dropdown:focus {
        .selected-flag {
          background-color: var(--fourthColor) !important;
        }
      }
    }
  }
}
